import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'

import ImageFour from '../components/images/image-four'
import ImageFive from '../components/images/image-five'
import ImageSix from '../components/images/image-six'
import ImageSeven from '../components/images/image-seven'


import step1 from '../images/Spain-Step1.png'
import step2 from '../images/Spain-Step2.png'
import step3 from '../images/Spain-Step3.png'
import step4 from '../images/Spain-Step4.png'

const CardOne = styled.div`
 display: grid;
 grid-gap: 20px;
 grid-template-columns: 200px 1fr 25%;

  @media ( max-width: 900px) {
    grid-template-columns: 100%;
  }

`
const CardTwo = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 200px 1fr;

  @media ( max-width: 900px) {
    grid-template-columns: 100%;
  }
`
const CardStep = styled.div`
 @media ( max-width: 900px) {
    text-align: center;

  }
`
const CardImages = styled.ul`
`
const CardContent = styled.div`
`



const ComoFuncionaPage = () => (
  <Layout>
    <SEO title="Como Funciona el Metodo Mckenzie " />
    <CardOne>
      <CardStep><img src={step1} alt={`step-one`} /></CardStep>
      <CardContent>
      <p>El Método McKenzie empieza cuando el Fisioterapeuta Acreditado en el método hace una historia detallada sobre los síntomas y su comportamiento para averiguar si están relacionados o no con posturas o movimientos que se realizan en la actividad cotidiana. </p>
      <p>Se le pedirá al paciente que haga determinados movimientos varias veces, también adoptar posturas mantenidas para evaluar la respuesta de los síntomas y la movilidad que tiene.</p> 
      <p>La principal diferencia con los otros métodos es que MDT utiliza los movimientos repetidos en lugar de un solo movimiento. Saber cómo cambian los síntomas con esos movimientos repetidos hará que el Fisioterapeuta McKenzie Acreditado tenga la información necesaria para llegar a una conclusión diagnóstica y diseñar un tratamiento adecuado.</p>
      </CardContent>
      <CardImages>
        <li><ImageFour /></li>
        <li><ImageFive /></li>
      </CardImages>
    </CardOne>
    <CardTwo>
    <CardStep><img src={step2} alt={`step-two`} /></CardStep>
      <CardContent>
        <p>Cada síndrome será tratado acorde a su naturaleza única, con procedimientos mecánicos específicos, que incluirán movimientos repetidos y posiciones mantenidas. </p>
        <p>MDT es un sistema de clasificación exhaustivo e incluye un pequeño grupo de pacientes que puede que no se incluyan en uno de los tres síndromes pero que se encuentran en el subgrupo OTROS que incluye patologías severas o graves, patologías de causas no mecánicas dolor crónico, etc.</p>
      </CardContent>
    </CardTwo>
    <CardOne>
      <CardStep><img src={step3} alt={`step-three`} /></CardStep>
      <CardContent>
      <p>Usando la información de la evaluación, si el fisioterapeuta lo encuentra adecuado, prescribirá unos ejercicios específicos y aconsejará determinadas posturas que se deben adoptar y otras que se deben evitar.  </p>
      <p>Si el problema o patología tiene una presentación mecánica más compleja, un Fisioterapeuta Acreditado en el Método McKenzie puede aplicar técnicas manuales 'avanzadas' hasta que el paciente sea capaz de autotratarse a través de ejercicios específicos y con la corrección de la postura. El objetivo es solucionar el problema lo antes posible con el mínimo número de sesiones en la clínica . </p> 
      <p>El tratamiento prescrito se debe realizar en casa cinco o seis veces al día, así se obtiene un resultado mucho más efectivo que con el tratamiento convencional de fisioterapia. </p>
      <p>es que el paciente se involucre en el tratamiento de una forma activa, pues permitirá que se recupere de sus dolencias más rápidamente. Se ha comprobado que con este método la mayoría de los pacientes puede autotratarse con éxito si se les da las herramientas y conocimientos necesarios para poder hacerlo. El fisioterapeuta McKenzie se encargará de dárselas.</p>
      </CardContent>
      <CardImages>
        <li><ImageSix /></li>
        <li><ImageSeven /></li>
      </CardImages>
    </CardOne>
    <CardTwo>
    <CardStep><img src={step4} alt={`step-four`} /></CardStep>
      <CardContent>
        <p>Cada síndrome será tratado acorde a su naturaleza única, con procedimientos mecánicos específicos, que incluirán movimientos repetidos y posiciones mantenidas. </p>
        <p>MDT es un sistema de clasificación exhaustivo e incluye un pequeño grupo de pacientes que puede que no se incluyan en uno de los tres síndromes pero que se encuentran en el subgrupo OTROS que incluye patologías severas o graves, patologías de causas no mecánicas dolor crónico, etc.</p>
      </CardContent>
    </CardTwo>
    <Link to="/es-apropiado/" className={`mdt-button-simple`}>Es Apropiado Para Mi?</Link>
  </Layout>
)

export default ComoFuncionaPage
