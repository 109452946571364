import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ImageSix = () => (
  <StaticQuery
    query={graphql`
      query {
        imageSix: file(relativePath: { eq: "mdt-4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1366) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.imageSix.childImageSharp.fluid} alt={`mdt-4`}/>}
  />
)
export default ImageSix
