import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ImageSeven = () => (
  <StaticQuery
    query={graphql`
      query {
        imageSeven: file(relativePath: { eq: "mdt-7.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1366) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.imageSeven.childImageSharp.fluid} alt={`mdt-7`}/>}
  />
)
export default ImageSeven
